import { decode } from 'jsonwebtoken';
import useLocalStorage from '@hooks/useLocalStorage';
import { JWT_STORAGE_KEY } from '@lib/constants';
import { isPlatformBrowser } from '@utils/platform';

/**
 * Check if the user has a valid JWT stored in his browser localstorage.
 * This not guarantees that the token is valid (signature might be corrupted) but covers most of the "normal" cases.
 * @return {boolean}
 */
export default function useJwt(): boolean {
  const [jwt, setJwt] = useLocalStorage<string>(JWT_STORAGE_KEY);
  if (!isPlatformBrowser()) {
    return false;
  }

  if (!jwt) {
    return false;
  }

  const exp = decode(jwt, { json: true })?.exp; // timestamp second

  if (!exp) {
    // Valid tokens should have a valid expiration timestamp
    setJwt(undefined);
    return false;
  }

  // Token is valid if exp is in the future
  return Date.now() < exp * 1000;
}
