import { useEffect } from 'react';
import type { QueryHookOptions } from '@apollo/client';
import type { MeQuery, MeQueryVariables } from '@graphql/client/generated/me.generated';
import { useMeQuery } from '@graphql/client/generated/me.generated';
import useGTM from '@hooks/useGTM';
import useJwt from '@hooks/useJwt';
import * as Sentry from '@sentry/nextjs';

export type UseUserOptions = Omit<QueryHookOptions<MeQuery, MeQueryVariables>, 'skip'>;

export default function useUser(options?: UseUserOptions) {
  const logged = useJwt();
  const sendToGTM = useGTM();

  const { data, refetch, ...others } = useMeQuery({
    skip: !logged,
    ...options,
    onCompleted: (data) => {
      options?.onCompleted?.(data);

      Sentry.setUser({
        id: data.me._id,
        email: data.me.email,
        username: data.me.username ?? undefined,
      });

      sendToGTM({ user_id: data.me._id });
    },
  });

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data === 'useUser:reload') {
        void refetch();
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  });

  return { user: data?.me, logged, refetch, ...others };
}
