import type { FC } from 'react';
import { createContext, useState } from 'react';

export const lockContext = createContext<{
  map: Record<string, boolean>;
  setterFactory: (key: string) => (value: boolean) => void;
}>({
  map: {},
  setterFactory: () => () => void 0,
});

const LockProvider: FC = ({ children }) => {
  const [map, setMap] = useState<Record<string, boolean>>({});

  const setterFactory = (key: string) => {
    return (value: boolean) => {
      setMap((prev) => {
        return { ...prev, [key]: value };
      });
    };
  };

  return <lockContext.Provider value={{ map, setterFactory }}>{children}</lockContext.Provider>;
};

export default LockProvider;
