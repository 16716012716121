import env from '@lib/app/env';
import type { AddEthereumChainParameter } from '@lib/web3/eip/EIP-3085';
import ChainId from '@lib/web3/lib/ChainId';
import dec2hex from '@lib/web3/utils/dec2hex';

export type RichEthereumChainParameter = AddEthereumChainParameter &
  Required<Pick<AddEthereumChainParameter, 'chainName' | 'nativeCurrency'>> & {
    chainId: ChainId;
    rpcUrls: [string, ...string[]];
  };

export const mappings: Record<ChainId, RichEthereumChainParameter> = {
  [ChainId.AVALANCHE_MAINNET]: {
    chainId: ChainId.AVALANCHE_MAINNET,
    chainName: 'Avalanche Mainnet C-Chain',
    iconUrls: ['https://snowtrace.io/images/svg/brands/mainbrand-1.svg'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io'],
  },
  [ChainId.AVALANCHE_TESTNET]: {
    chainId: ChainId.AVALANCHE_TESTNET,
    chainName: 'Avalanche Testnet C-Chain',
    iconUrls: ['https://snowtrace.io/images/svg/brands/mainbrand-1.svg'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
  },
  [ChainId.AVALANCHE_LOCALNET]: {
    chainId: ChainId.AVALANCHE_LOCALNET,
    chainName: 'Avalanche Localnet C-Chain',
    iconUrls: ['https://snowtrace.io/images/svg/brands/mainbrand-1.svg'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['http://localhost:8545'],
  },
};

export const CHAIN_ID: ChainId = (
  env.NEXT_PUBLIC_WEB3_CHAIN.startsWith('0x')
    ? env.NEXT_PUBLIC_WEB3_CHAIN
    : dec2hex(parseInt(env.NEXT_PUBLIC_WEB3_CHAIN, 10))
) as ChainId;

export const CHAIN: RichEthereumChainParameter = mappings[CHAIN_ID];
