import { useApolloClient } from '@apollo/client';
import { MeDocument } from '@graphql/client/generated/me.generated';
import useLocalStorage from '@hooks/useLocalStorage';
import useUser from '@hooks/useUser';

const IMPERSONATE_KEY = 'impersonate.id';

export default function useImpersonate() {
  const { user } = useUser();
  const client = useApolloClient();
  const [id, setId] = useLocalStorage<string>(IMPERSONATE_KEY);

  const handleSet: typeof setId = (v) => {
    setId(v);
    void client.refetchQueries({
      include: [MeDocument],
    });
  };

  return {
    email: user?.email,
    wallet: user?.wallet?.address,
    impersonating: id,
    impersonate: handleSet,
    stop: () => handleSet(undefined),
  };
}
