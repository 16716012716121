import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { isPlatformBrowser } from '@utils/platform';

export default function useLocalStorage<T = undefined>(
  key: string,
  initialValue?: T,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [state, setState] = useState<T | undefined>(() => {
    if (!isPlatformBrowser()) {
      return initialValue;
    }

    const found = window.localStorage.getItem(key);

    try {
      return found ? (JSON.parse(found) as T) : initialValue;
    } catch (e) {
      if (e instanceof SyntaxError) {
        window.localStorage.removeItem(key);
      }

      return initialValue;
    }
  });

  const setValue: Dispatch<SetStateAction<T | undefined>> = (value) => {
    const store: T | undefined = value instanceof Function ? value(state) : value;
    setState(store);

    if (!isPlatformBrowser()) {
      return;
    }

    if (store === null || store === undefined) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, JSON.stringify(store));
    }
  };

  return [state, setValue];
}
