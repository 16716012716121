import { BigNumber } from '@ethersproject/bignumber';
import type { Tier } from '@lib/types/KYCTier';
import e from '@lib/web3/utils/e';

export const JWT_STORAGE_KEY = 'ico-auth-jwt';

// Transactions id namespace
export const TRANSACTION_NAMESPACE = '0d7ab97b-64a0-457d-a100-7e28902a96bf';

// Currencies
export const EUR_TO_USD_CONVERSION_RATE = 100;
export const CHF_TO_USD_CONVERSION_RATE = 100;

// Discounts
export const REFERRAL_PERCENTAGE = 12; // 12%
export const MIN_DPS_INVESTMENT_FOR_DISCOUNT: BigNumber = BigNumber.from('25000').mul(e(18)); // 25,000

// IBAN payment info
export const USD_IBAN_DEPOSIT_MAX_AMOUNT_TIER1 = 50000; // USD
export const USD_IBAN_DEPOSIT_MAX_AMOUNT_TIER2 = 100000; // USD
export const USD_IBAN_DEPOSIT_MAX_AMOUNT_TIER3 = Infinity; // USD

// Default text
export const NA = 'N/A';

// KYC
export const KYC_TIERS: { TIER_1: Tier; TIER_2: Tier; TIER_3: Tier } = {
  TIER_1: {
    id: 'identity',
    title: 'Tier 1',
    name: 'Identity verification',
    limit: USD_IBAN_DEPOSIT_MAX_AMOUNT_TIER1,
    url: '/verification/identity',
    color: 'primary',
  },
  TIER_2: {
    id: 'document',
    title: 'Tier 2',
    name: 'Address verification',
    limit: USD_IBAN_DEPOSIT_MAX_AMOUNT_TIER2,
    url: '/verification/document',
    color: 'secondary',
  },
  TIER_3: {
    id: 'boardMeeting',
    title: 'Tier 3',
    name: 'Board meeting',
    limit: Infinity,
    color: 'neutral',
  },
};
