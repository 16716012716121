import { useRouter } from 'next/router';
import { isAndroid, isIOS } from 'react-device-detect';
import { useEffect, useMemo, useRef } from 'react';
import useImpersonate from '@hooks/useImpersonate';
import useLock from '@hooks/useLock';
import { CHAIN, CHAIN_ID } from '@lib/web3/constants/chains';
import { isProviderRpcError } from '@lib/web3/eip/EIP-1193';
import type EIP1193Provider from '@lib/web3/providers/EIP1193Provider';
import MetaMaskOnboarding from '@metamask/onboarding';
import hex2dec from '@utils/hex2dec';
import { isPlatformBrowser } from '@utils/platform';
import { useWeb3React } from '@web3-react/core';
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { InjectedConnector } from '@web3-react/injected-connector';

interface UseWeb3Output extends Omit<Web3ReactContextInterface<EIP1193Provider>, 'activate'> {
  installed: boolean;
  isChainSupported: boolean;
  activate: () => Promise<void>;
  changeNetwork: () => Promise<void>;
}

/**
 * Main hook to interact with the Web3 API.
 * @returns {UseWeb3Output}
 */
export default function useWeb3(): UseWeb3Output {
  const router = useRouter();
  const { isLocked: isSwitchLocked, lock: lockSwitch, unlock: unlockSwitch } = useLock('wallet_switchEthereumChain');
  const { isLocked: isAddLocked, lock: lockAdd, unlock: unlockAdd } = useLock('wallet_addEthereumChain');
  const { impersonating, wallet } = useImpersonate();
  const { activate, account, ...web3 } = useWeb3React<EIP1193Provider>();
  const { library } = web3;
  const connector = useMemo(
    () =>
      new InjectedConnector({
        // supportedChainIds: Object.values(ChainId).map(hex2dec),
      }),
    [],
  );
  const onboarding = useRef<MetaMaskOnboarding>();

  useEffect(() => {
    onboarding.current = new MetaMaskOnboarding();
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled() && account) {
      onboarding.current?.stopOnboarding();
    }
  }, [account]);

  const installed = isPlatformBrowser() && Boolean(window.ethereum);

  return {
    ...web3,
    installed,
    account: !impersonating ? account : wallet,
    isChainSupported: web3.chainId === hex2dec(CHAIN_ID),
    changeNetwork: async () => {
      /** @see https://docs.metamask.io/guide/rpc-api.html#usage-with-wallet-switchethereumchain */
      // This avoids user to send multiple time the same RPC request, which will result in RPC error ("request of this type already pending")
      if (!isSwitchLocked) {
        lockSwitch();
        try {
          await library?.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: CHAIN.chainId }] });
        } catch (e) {
          if (isProviderRpcError(e)) {
            switch (e.code) {
              case 4902: // Code 4902 means that the chain is not recognized by the wallet
              case -32603: // Code -32603 does the same on MetaMask mobile
                if (!isAddLocked) {
                  lockAdd();
                  await library?.request({ method: 'wallet_addEthereumChain', params: [CHAIN] }).finally(unlockAdd);
                }
                break;
              // Code 4001 means that the user refused the switch
              case 4001:
                break;
              default:
                throw e;
            }
          } else {
            throw e;
          }
        } finally {
          unlockSwitch();
        }
      }
    },
    activate: async () => {
      if (installed) {
        await activate(connector);
      } else if (isIOS || isAndroid) {
        await router.push('/faq?q=how-to-manually-add-the-avalanche-network-on-mobile');
      } else if (onboarding.current) {
        onboarding.current.startOnboarding();
      }
    },
  };
}
